<template>
<div>
  <div>
    <el-button class="blue-btn"
               @click="open">弹出dialog</el-button>
    <el-dialog :show-close="false"
               :before-close="close"
               :visible.sync="dialogVisible"
               custom-class="hint"
               top="50px"
               :width="width + 'px'"
               center>
      <div class="imgwrapper">
        <el-button class="dark-grey-btn left"
                   @click="left">左</el-button>

        <img :src="imgList[index].imgUrl"
             class="img"
             ref="imgs"
             :style="{width: imgWidth + 'px', height: imgHeigth + 'px'}"
             @load="onload" />
        <el-button class="dark-grey-btn right"
                   @click="right">右</el-button>
      </div>

      <span slot="footer"
            class="dialog-footer">
        <el-button class="dark-grey-btn"
                   @click="close">关闭</el-button>
      </span>
    </el-dialog>
  </div>




  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
    components:{
 
    },
 data(){
    return {
   index: 0,
      width: '', // dialog 宽
      imgWidth: '', // 要设置的img宽
      imgHeigth: '', // 要设置的img高
      dialogVisible: false,
      imgList: [
        {
          imgUrl: 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3681705955,4153554780&fm=26&gp=0.jpg'
        },
        {
          imgUrl: 'http://192.168.188.127:8711/upload/veterans_dev/manageImage/11.jpg'
        },
        {
          imgUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1093300176,3481197507&fm=26&gp=0.jpg'
        },
        {
          imgUrl: 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2156271884,4153213391&fm=26&gp=0.jpg'
        }
      ]
    
    }
  },
  created () {  
  },
mounted() {


  },
  computed: {
    winWidth: function () {
      return document.documentElement.clientWidth || document.body.clientWidth;
    },
    winHeight: function () {
      return document.documentElement.clientHeight || document.body.clientHeight;
    }
  },
  methods: {  
   onload (e) { // 图片加载时
      let imgW = e.target.naturalWidth// 图片宽
      let imgH = e.target.naturalHeight // 图片高
      let maxWidth = 1240
      // 如果图片宽高小于浏览器,不做改变
      if (imgW < maxWidth && imgH < this.winHeight - 251) {
        this.width = imgW + 50
        this.imgWidth = imgW
        this.imgHeigth = imgH
      } else {
        //图片的宽高比例大于dialog最大宽高比时，以dialog内容的最大宽度为图片的宽度
        if ((maxWidth - 50) / (this.winHeight - 251) <= imgW / imgH) {
          this.width = maxWidth
          this.imgHeigth = (maxWidth - 50) / (imgW / imgH)
          this.imgWidth = maxWidth - 50 // 减去dialog的左右padding
        } else {
          this.imgWidth = (this.winHeight - 251) * (imgW / imgH)
          this.width = this.imgWidth + 50
          this.imgHeigth = this.winHeight - 255 // 减去dialog的padding和其他内容部分
        }
      }
    },
    close () {
      this.dialogVisible = false
    },
    open () {
      this.dialogVisible = true
    },
    right () {
      if (this.index < this.imgList.length - 1) {
        this.index++
      }
    },
    left () {
      if (this.index > 0) {
        this.index--
      }
    }
  }
  

}
</script>

<style lang="less" scoped>

</style>